import photoprofile from '../image/photoprofile.jpeg';
import ijasah from '../image/ijasah.jpg';
import transkipnilaid from '../image/transkipnilaid.jpg';
import transkipnilaib from '../image/transkipnilaib.jpg';
import sertifikat from '../image/sertifikat.jpg';

const Profile = () => {
	return (
	<div>
		<div className="text-white my-8">
        		<div className="mx-auto flex flex-wrap items-center border border-gray-800 rounded-md p-4">
          			<div className="h-full lg:w-1/4 pr-0 pb-2 md:pr-2 md:pb-0">
          				<img className="h-100 rounded-md" src={photoprofile} alt="Foto Profil" />
          			</div>
          			<div className="h-full lg:w-3/4 text-left pl-0 pt-2 md:pl-2 md:pt-0">
              				<h1 className="font-semibold text-white text-xl">
						Andreas Dani Setyawan
					</h1>
              				<p className="text-zinc-300 text-sm mb-2 pb-2">
						@andreazetyawan
					</p>
              				<p className="text-zinc-300 text-sm mb-2 pb-2">
                				Hallo selamat datang di situs saya, Saya adalah seorang penggemar dalam bidang teknologi.
                				Memulai karir dibidang developer website pada tahun 2017 kemudian berpindah
                				dibidang digital marketing dengan mengelola web, media sosial serta pembuatan konten
                				untuk tujuan pemasaran digital.
              				</p>
              				<div className="flex text-sm item-center">
						<p className="mr-2 text-zinc-300">
							Terhubung dengan saya : 
						</p>
                				<a className="flex hover:text-blue-800" href="mailto:andreazetyawan@andreazetyawan.id">
                					<svg class="w-6 h-6 hover:text-blue-800 mr-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  					<path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                					</svg>
                				</a>
                				<a className="flex hover:text-blue-800" href="https://github.com/andreazetyawan">
                					<svg class="w-6 h-6 hover:text-blue-800 mx-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  					<path fill-rule="evenodd" d="M12.006 2a9.847 9.847 0 0 0-6.484 2.44 10.32 10.32 0 0 0-3.393 6.17 10.48 10.48 0 0 0 1.317 6.955 10.045 10.045 0 0 0 5.4 4.418c.504.095.683-.223.683-.494 0-.245-.01-1.052-.014-1.908-2.78.62-3.366-1.21-3.366-1.21a2.711 2.711 0 0 0-1.11-1.5c-.907-.637.07-.621.07-.621.317.044.62.163.885.346.266.183.487.426.647.71.135.253.318.476.538.655a2.079 2.079 0 0 0 2.37.196c.045-.52.27-1.006.635-1.37-2.219-.259-4.554-1.138-4.554-5.07a4.022 4.022 0 0 1 1.031-2.75 3.77 3.77 0 0 1 .096-2.713s.839-.275 2.749 1.05a9.26 9.26 0 0 1 5.004 0c1.906-1.325 2.74-1.05 2.74-1.05.37.858.406 1.828.101 2.713a4.017 4.017 0 0 1 1.029 2.75c0 3.939-2.339 4.805-4.564 5.058a2.471 2.471 0 0 1 .679 1.897c0 1.372-.012 2.477-.012 2.814 0 .272.18.592.687.492a10.05 10.05 0 0 0 5.388-4.421 10.473 10.473 0 0 0 1.313-6.948 10.32 10.32 0 0 0-3.39-6.165A9.847 9.847 0 0 0 12.007 2Z" clip-rule="evenodd"/>
                					</svg>
                				</a>
                				<a className="flex hover:text-blue-500 dark:hover:text-blue-400" href="https://x.com/andreazetyawan">
                					<svg class="w-6 h-6 hover:text-blue-800 mx-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                					<path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z"/>
                					</svg>
                				</a>
                				<a className="flex hover:text-blue-500 dark:hover:text-blue-400" href="https://instagram.com/andreazetyawan">
                					<svg class="w-6 h-6 hover:text-blue-800 mx-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  					<path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd"/>
                					</svg>
                				</a>
              				</div>
          			</div>
          		</div>
        	</div>

       		<div className="text-white my-8">
        		<div className="flex flex-row border border-gray-800 rounded-md p-4">
          			<div className="basis-full">
            				<div className="text-left">
              					<p className="text-zinc-400 text-sm flex items-center">
                					<svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  					<path fill-rule="evenodd" d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z" clip-rule="evenodd"/>
                  					<path fill-rule="evenodd" d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z" clip-rule="evenodd"/>
                					</svg>
                					Pendidikan Terakhir
              					</p>
              					<h1 className="font-semibold text-white text-xl">
							D3 Manajemen Informatika
						</h1>
              					<p className="text-zinc-400 text-sm  mb-2 pb-2">
							Politeknik Indonusa Surakarta | 2013 - 2016
						</p>
              					<div className="flex items-center flex-nowrap">
                					<div>
                  						<img className="h-100 p-1 rounded-lg" src={ijasah} alt="Ijasah" />
                					</div>
                					<div>
                  						<img className="h-100 p-1 rounded-lg" src={transkipnilaid} alt="Transkrip Nilai Depan" />
                					</div>
                					<div>
                  						<img className="h-100 p-1 rounded-lg" src={transkipnilaib} alt="Transkrip Nilai Belakang" />
                					</div>
                					<div>
                  						<img className="h-100 p-1 rounded-lg" src={sertifikat} alt="Sertifikat" />
                					</div>
              					</div>
            				</div>
          			</div>
          		</div>
        	</div>
        </div>
  );
}

export default Profile;
