import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import Profile from './pages/Profile';
import Work from './pages/Work';
import Project from './pages/Project';
import NotFound from './pages/NotFound';

const App = () => {
    return (
    <section className="min-h-screen px-6 py-10 sm:py-10 lg:px-8 bg-zinc-950">
      <div className="mx-auto max-w-3xl shadow-sm rounded-md">
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Profile/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/work" element={<Work/>} />
                <Route path="/project" element={<Project/>} />
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </BrowserRouter>
        <Footer/>
      </div>
    </section>
    );
}

export default App;