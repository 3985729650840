const NotFound = () => {
  return (
    <div>
        <div className="text-center text-white rounded-lg border-slate-800 border my-6 p-12">
          <h1 className="font-semibold text-white text-4xl">404</h1>
          <h1 className="font-semibold text-white text-xl">Halaman Tidak Ditemukan</h1>
          <p className="text-zinc-400 text-md">Sepertinya halaman yang kamu cari tidak ditemukan atau mungkin sudah dihapus.</p>
        </div>
    </div>
  );
}

export default NotFound;
