const Project = () => {
  return (
    <div>
        <div className="text-white pt-8">
        <h1 className="text-white text-xl font-semibold">
          Daftar Project 
        </h1>
        <p className="text-sm text-zinc-400 text-md pb-7">
          Project pertama kali yang pernah saya buat saat magang di salah satu sekolah negeri surakarta, berikut adalah daftar project yang pernah saya buat. 
        </p>
        <div className="flex flex-row border border-gray-800 rounded-md p-4 mb-4">
          <div className="basis-full">
            <div className="text-left">
              <p className="text-zinc-400 text-md flex items-center text-sm">
                <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                </svg>
                Aplikasi Perpustakaan Berbasis Website (Magang)
              </p>
              <h1 className="font-semibold text-white text-xl">SMK Negeri 6 Surakarta</h1>
              <ul class="list-disc text-zinc-400 text-md ml-4 text-sm ">
                <li>Membuat aplikasi perpusatakaan lokal berbasis website dengan PHP</li>
                <li>Membuat fitur scan pengunjung dengan kartu siswa</li>
                <li>Membuat fitur pinjam dan pengembalian buku</li>
              </ul>
              <a className="flex text-blue-800 text-sm flex items-center" href="https://smkn6solo.sch.id"> 
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
                smkn6solo.sch.id
              </a>
            </div>
          </div>          
          </div>
        <div className="flex flex-row border border-gray-800 rounded-md p-4 mb-4">
          <div className="basis-full">
            <div className="text-left">
              <p className="text-zinc-400 text-md flex items-center text-sm ">
                <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                </svg>
                Katalog Produk Website
              </p>
              <h1 className="font-semibold text-white text-xl">PT Jetak Makmur Lestari</h1>
              <ul class="list-disc text-zinc-400 text-md ml-4 text-sm ">
                <li>Membuat katalog produk berbasis website dengan wordpress</li>
                <li>Pengelolaan server website</li>
              </ul>
              <a className="flex text-blue-800 text-sm flex items-center" href="http://rainwearindustry.com"> 
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
                rainwearindustry.com
              </a>
            </div>
          </div>          
          </div>
          <div className="flex flex-row border border-gray-800 rounded-md p-4 mb-4">
          <div className="basis-full">
            <div className="text-left">
              <p className="text-zinc-400 text-md flex items-center text-sm ">
                <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                </svg>
                Company Profile Perusahaan Berbasis Website
              </p>
              <h1 className="font-semibold text-white text-xl">PT Solo Mechatronic Indonesia</h1>
              <ul class="list-disc text-zinc-400 text-md ml-4 text-sm ">
                <li>Membuat company profile berbasis website dengan wordpress</li>
                <li>Integrasi system IoT (Internet of Things) dengan protokol mqtt dan modbus berbasis web dan dekstop</li>
                <li>Pengelolaan server website</li>
              </ul>
              <a className="flex text-blue-800 text-sm flex items-center" href="https://solomechatronic.com"> 
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
                solomechatronic.com
              </a>
            </div>
          </div>
          </div>

          <div className="flex flex-row border border-gray-800 rounded-md p-4 mb-4">
          <div className="basis-full">
            <div className="text-left">
              <p className="text-zinc-400 text-md flex items-center text-sm ">
                <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                </svg>
                Company Profile Perusahaan Berbasis Website
              </p>
              <h1 className="font-semibold text-white text-xl">PT Cipta Otomasi Indonesia</h1>
              <ul class="list-disc text-zinc-400 text-md ml-4 text-sm ">
                <li>Membuat company profile berbasis website dengan wordpress</li>
                <li>Pengelolaan server website</li>
              </ul>
                <a className="flex text-blue-800 text-sm flex items-center" href="https://ciptaotomasiindonesia.com"> 
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
                ciptaotomasiindonesia.com
              </a>
            </div>
          </div>          
          </div>

          <div className="flex flex-row border border-gray-800 rounded-md p-4 mb-4">
          <div className="basis-full">
            <div className="text-left">
            <p className="text-zinc-400 text-md flex items-center text-sm ">
                <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6"/>
                </svg>
                Katalog Produk Website
              </p>
              <h1 className="font-semibold text-white text-xl">Nonieta Homedress</h1>
              <ul class="list-disc text-zinc-400 text-md ml-4 text-sm ">
                <li>Membuat katalog produk berbasis website dengan serverless cloudflare</li>
                <li>Pengelolaan serverless cloudflare</li>
              </ul>
              <a className="flex text-blue-800 text-sm flex items-center" href="https://nonietahomedress.pages.dev"> 
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                </svg>
                nonietahomedress.pages.dev
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
  );
}

export default Project;
